import React from 'react';

export default function InfoCard(props) {
    return (
        <div className="info-card"style={{
            backgroundColor: props.backgroundColor
        }}>
            <img className="card-image" src={props.img}/>
            <h1>{props.title}</h1>
            <h4>{props.description}</h4>
        </div>
    )
}