export default [
    {
        img:'mealswipes.jpg',
        name:'MealSwipes - Build Your Own Cookbook',
        year:'Javascript, React, Firebase, Edamame API',
        hometown:'June 2022',
        description:'An application where users are fronted with various recipes - have options to save, favorite, and skip. Users have access to personalized cookbook, compiled of all liked and favorited recipes. Designed the frontend with JavaScript and React and the backend, including user authentication and database management was handled through Firebase. Recipes are sourced from Edamame API.',
        position:'React, Firebase, APIs, FrontEnd'
    },
    {
        img:'discordstockbot.jpg',
        name:'Discord Stock Bot',
        year:'Java, Discord JDA, Webscraping, MySQL, myPHP',
        hometown:'December 2021',
        description:'A discord bot that displays stock market data. It has capabilities to project individual stock data, groups of greatest and worst doing stocks, and users can establish a collection of indicated stocks to keep track of.Coded in Java utilizing the Discord JDA. Web scrapes stock data from Market Insider’s website and stores user watchlist data in MySQL database that is hosted locally using myPHP',
        position:'Java, Discord JDA, Webscraping, Bot'
    },
    {
        img:'calorietracker.jpg',
        name:'Calorie Tracker Project',
        year:'Java, JavaFX, mySQL, myPHP, OOP',
        hometown:'May 2021',
        description:'A program in which a user can log-in, pick a date and log food for that specific date. The program keeps track of the user’s total calories, macronutrient content, and remaining calories for the day. GUI built with JavaFX, database managed by SQL and myPHP hosting, programmed in Java and was objectoriented. ',
        position:'Java, JavaFX, mySQL, myPHP, OOP'
    }
]