import React from 'react';
import './App.css';
import Navbar from './Navbar';
import Hero from './Hero';
import InfoSection from './InfoSection'
import Members from './Members'

function App() {
  return (
    <div>
      <div className="diagonal-box">
        <div className="content"></div>
      </div>
      <Navbar />
      <Hero />
      <InfoSection />
      <Members />
    </div>
  )
}

export default App;
