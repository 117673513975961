import React from "react"
import InfoCard from "./InfoCard"

export default function InfoSection() {
    return (
        <div className="info-section" id="info-section">
            <div className="cards">
                <InfoCard 
                    img="brainlogo.png" 
                    backgroundColor="#fa9600" 
                    title="EDUCATION" 
                    description="I currently attend the University of Texas at Austin, pursuing
                    a Bachelor's Degree in Computer Science. I am a sophomore, with expectations
                    to graduate in the Fall of 2024. Relevant Coursework Includes: Introduction
                    to Java Programming, Data Structures, Discrete Math, Computer Architecture,
                    and Introduction to Management Information Systems."
                />
                <InfoCard
                    img="Technology-Consulting-1000.png"
                    backgroundColor="#bc2bff"
                    title="EXPERIENCE"
                    description="This summer I had the opportunity to work at SpacedVentures,
                    a fintech startup acting as a communal investment portal for spatial startups.
                    Some of my work responsibilites include, but are not limited to: 
                    • Generated scripts in Python, using both the Beautiful Soup and Microsoft Excel Library, to web scrape NASA 
                    award and contract data and apply them to an excel spreadsheet - assembled over 1.6 million data entries and 
                    information related to 10000 companies.
                    • Developed a company pitch builder application in Python that when fed a company’s website, generated a first 
                    draft pitch in Microsoft Word. Recursively navigated through website pages, outsourcing data routed through href 
                    links. Implemented openai API to summarize TLDR statements and docx library to write data to a Word document
                    " 
                />
                <InfoCard 
                    img="socialmedia.png" 
                    backgroundColor="#fa9600" 
                    title="CLUB AFFILIATIONS" 
                    description="Hispanic Association of Computer Scientists - Member:
                    • Participate in tech-industry based presentations that discuss various computer science opportunities. 
                    • Contributed to the HACS website open-source software, using HTML, CSS, JavaScript and React
                    Texas Blockchain Engineering - Member:
                    • Discuss ideas relating to blockchain, web3, and the Ethereum main net and protocols.
                    • Participate in solidity workshops / discussions / hackathons to demonstrate direct implementation of the 
                    blockchain.
                    "
                />
            </div>
        </div>
    )
}