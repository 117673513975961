import React from "react"
import memberData from './memberData'

function Member(props) {
    return (
        <div className="card-container">
            <div className="card">
                <figure className="front" style={{
                     backgroundImage: `url(${props.img})`
                }}>
                    <div className="header-info">
                        <h1>{props.name}</h1>
                        <h3>{props.position}</h3>  
                    </div>
                </figure>
                <figure className="back" style={{
                    fontSize: '.7rem'
                }}>
                    <div className="header">
                        <h1>{props.name}</h1>
                    </div>
                    <h2 className="year">{props.year}</h2>
                    <h2 className="hometown" style={{marginBottom:'1rem'}}>{props.hometown}</h2>
                    <h2 className="description">{props.description}</h2>
                </figure>
            </div>
        </div>
    )
}

export default function Members() {
    const members = memberData.map(member => {
        return <Member 
                    img={member.img}
                    name={member.name}
                    position={member.position}
                    year={member.year}
                    hometown={member.hometown}
                    description={member.description}
                    email={member.email}
                    linkedin={member.linkedin}
                />
    })
    return (
        <div className="member-section" id="member-section">
            {members}
        </div>
    )
}