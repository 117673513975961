import React from 'react';
import { BrowserRouter } from "react-router-dom"
import { HashLink as Link } from "react-router-hash-link"

export default function Navbar() {
    return (
        <BrowserRouter>
            <div className="nav-bar">
                <h1 className="header-logo">Ethan.dev</h1>
                <ul className="nav">
                    <Link to="#info-section" className='link'><a>ABOUT</a></Link>
                    <Link to="#member-section" className='link'><a>PROJECTS</a></Link>
                    <a href="mailto:ethanslopez@utexas.edu" className='link'>CONTACT</a>
                </ul>
            </div>
        </BrowserRouter>
    )
}