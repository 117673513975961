import React from "react";
import Spline from '@splinetool/react-spline';

export default function Hero() {
    return (
        <div className="hero-section">
            <div className="hero-body">
                <h1>
                    Ethan Lopez,
                    Software Developer
                </h1>
                <h3>
                    Experience in Front-End Web Development,
                    Database Management, and Data Science with
                    Python.
                </h3>
                <div className="hero-buttons">
                    <button className="hero-button"><a href="https://www.linkedin.com/in/ethan-lopez-315529218/">LINKEDIN</a></button>
                    <button className="hero-button"><a href="mailto:ethanslopez@utexas.edu">CONTACT</a></button>
                </div>
            </div>
        </div>
    )
}